@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300&display=swap');

.App {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.Mobile {
  position: relative;
  margin: auto;
  text-align: center;
  width: 100%;
  height: 100vh;
  padding-left: 10%;
  padding-right: 10%;
  word-break: break-word;
  background-color: black;
  color: white;
}

a {
  text-decoration: none !important;
}

.commonSection {
  background-color: lightgray;
  background-image: url('./../images/backgroundWithLogo.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.dashboardSection {
  background-color: lightgray;
  background-image: url('./../images/dashboardBackGround.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.baethoLogo {
  position: relative;
  left: 13%;
  width: 140px;
  height: 86px;
  top: 50%;
}

.hide {
  display: none !important;
}

.leftAlign {
  text-align: left;
}

.rigthAlign {
  text-align: right;
}

.noOutline {
  outline: none;
}

.noBorder {
  border: none;
}

.inputLabels {
  font: normal normal normal 16px/18px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  padding: 4% 2% 4% 0%;
}

.inputFields {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 15px;
  width: 100%;
  margin-bottom: 10%;
  padding: 5% 2% 5% 4%;
  font-size: 15px;
  height: 18px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.rdt_TableHeader {
  display: none !important;
}

.submitButtonSection {
  position: relative;
  z-index: 99;
}

/* Main Content Container Css*/

.middleContent {
  background-color: #F7F8FA;
  padding-left: 90px;
  min-height: 100vh;
  padding-right: 1.5rem !important;
}

/* End Main Content Container Css*/

/* Header Css  */

/*  Buy Licenses Button Header */

.headerButton {
  width: 200px;
  height: 59px;
  background: #322A7D 0% 0% no-repeat padding-box;
  border-radius: 6px;
  font: normal normal 900 16px/22px Lato;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-top: 27px;
}

.headerTitle {
  color: #11141A;
  padding-top: 27px;
  font-weight: bold;
  text-align: left;
  font: normal normal bold 53px/32px Raleway;
  letter-spacing: 0px;
  height: 62px;
}

.headerSubTitle {
  text-align: left;
  font: normal normal normal 12px/14px Lato;
  letter-spacing: 0px;
  color: #7F8FA4;
  height: 20px;
}

/* End Header Css  */

/* BackDrop CSS */

.backdropDiv {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  opacity: 0.9;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Disable Links  */

.disabled-link {
  pointer-events: none;
}

.margin-auto {
  margin: auto !important;
}

.loader {
  position: absolute;
  top: 0px;
  bottom: 0px;
  z-index: 2000;
  background: lightgray;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  left: 0px;
  right: 0px;
}

.loader svg {
  top: 42%;
  bottom: 50%;
  left: 46%;
  right: 50%;
  position: absolute;
  z-index: 2000;
}

.addExperience .closeSideTab {
  float: right;
  font-weight: 600;
  font-size: 24px;
  color: #93c95b;
  cursor: pointer;
  position: absolute;
  top: 2%;
  right: 8%;
}

.sc-crzoAE {
  text-align: center;
  font: normal normal normal 16px/19px Lato !important;
  letter-spacing: 0px;
  color: #7F8FA4 !important;
  font-weight: 500 !important;
}

.rdt_Pagination {
  border: none !important;
}

.backIcon {
  cursor: pointer;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 6px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: darkgray;
}

.downloadScreen {
  /* position: absolute;
  top: 50%;
  left: 39%; */
  font-family: 'Mulish';
  font-weight: bold;
  font-size: 20px;
  z-index: 99;
  display: block;
}

.iframeDiv {
  z-index: 9999;
  position: relative;
  display: block;
}

.normalDimensions {
  display: block;
}

.ShowTurnPhone,
.LandscapeDimensions {
  display: none !important;
}

.cQdWYQ,
.jOtJgT {
  background-color: #A9DABC !important;
}

@media screen and (orientation:portrait)

/* and (min-device-width: 319px)  */
and (max-width: 560px) {

  .iframeDiv,
  .downloadScreen,
  .normalDimensions,
  .LandscapeDimensions,
  .individual-student,
  .studentScreenPortrait {
    display: none !important;
  }

  .ShowTurnPhone {
    display: block !important;
    padding-top: 50px;
    text-align: center;
    margin-bottom: 20px;
  }

}

@media screen and (orientation:landscape)

/* and (min-device-width: 319px)  */
and (max-width: 560px) {

  .iframeDiv,
  .downloadScreen,
  .LandscapeDimensions {
    display: block !important;
  }

  .ShowTurnPhone,
  .normalDimensions {
    display: none !important;
  }

}