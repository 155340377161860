/* Login Page */

body {
    font-family: "Montserrat", sans-serif;
    background-color: transparent;
}

.loginScreen,
.recoveryScreen,
.resetPasswordScreen {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
}

.login,
.recover,
.resetPassword {
    position: relative;
    background-image: url('./../images/Group 67@2x.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 400px;
    height: 600px;
    left: 39.5%;
    padding: 3% 3%;
}

.welcomeText {
    font: normal normal bold 35px/43px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 5%;
    margin-bottom: 3%;
}

.signInText {
    text-align: left;
    font: normal normal bold 29px/35px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    padding-bottom: 10%;
}

.loginScreen .forgetPassword {
    font: normal normal normal 13px/16px Montserrat;
    letter-spacing: 0px;
    color: #808083 !important;
    cursor: pointer;
}

.loginScreen .rememberMeText {
    text-align: left;
    font: normal normal normal 12px/16px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    padding-top: 10px;
    padding-left: 6px;
}

.loginButton,
.loginButton:hover {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    background-color: #282828;
    font: normal normal normal 14px/20px Montserrat;
    letter-spacing: 0px;
    color: #FEFEFE;
    width: 60%;
    margin-top: 25%;
}

/* End Login Page */

/* Recovery Screen */

.recoverExtraStyle {
    margin-top: 10%;
}

.recoveryText {
    text-align: left;
    font: normal normal bold 29px/35px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 35%;
    margin-bottom: 0px;
}

.recoverySubTitle {
    font: normal normal normal 10px/18px Montserrat;
    letter-spacing: 0px;
    color: #848988;
    margin-bottom: 10%;
}

/* End Recovery Screen */

/* Reset Password Screen */

.resetPasswordExtraStyling {
    margin-top: 10%;
}

.resetPassTitle {
    text-align: left;
    font: normal normal bold 25px/30px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 0px;
}

.resetPassSubTitle {
    font: normal normal normal 10px/18px Montserrat;
    letter-spacing: 0px;
    color: #808083;
    padding-top: 2%;
    margin-bottom: 17%;
}

/* End Reset Password Screen */

/* Left Side Panel  */

.left-sidebar {
    background: #FFF;
    height: 100vh;
    text-align: left;
    width: 70%;
    max-width: 70%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 64px 0px 0px;
    display: inline-block;
}

.menuList {
    display: table;
    height: 90vh;
    overflow: hidden;
}

.menu-items {
    padding-left: 0rem;
    position: relative;
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
}

.menu-item {
    font: normal normal normal 13px/16px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    list-style: none;
    background: #FFF;
    border-radius: 0 50px 0 0;
    width: 100%;
    height: 60px;
    text-align: left;
    padding-left: 15%;
}

.menu-item .content {
    position: relative;
    top: 38%;
}

.menu-icons {
    width: 15px;
    height: 15px;
    margin-right: 3%;
}

.menu-items .active {
    background: #AEDABC;
    font-weight: bold;
}

/* Left Side Panel end */

/*  Analytics Dashboard */

.analyticsDashboard .data-container-wrapper {
    height: 800px;
}

.analyticsDashboard .dateFields {
    padding: 12% 15% 0% 16%;
}

.analyticsDashboard .downloadBtn {
    background: #282828 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    text-align: center;
    font: normal normal normal 20px/24px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    width: 12%;
    padding: 10px;
}

/* End Analytics Dashboard */

.main-content {
    display: inline-block;
    width: 100%;
    max-width: 100%;
    padding-right: 4%;
}

.header-title {
    text-align: left;
    font: normal normal bold 50px/61px Montserrat;
    letter-spacing: 0px;
    color: #000000;
}

.profile-name {
    font-size: 24px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

.data-list-container {
    width: 100%;
    margin-top: 20px;
    height: 75%;
    overflow-y: scroll;
}

.data-list-wrapper {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    box-shadow: inset 0px 3px 20px #00000029, 0px 3px 10px #00000029;
    border: 7px solid #FFFFFF;
    border-radius: 16px;
    background: #A9DABC;
}

.data-list {
    font-size: 2rem;
    width: 100%;
    height: 90%;
}

.btn-action {
    text-align: center;
    font: normal normal normal 15px/20px Montserrat;
    color: #FFFFFF;
    background: #282828;
    margin-right: 10px;
    width: 84px;
    height: 26px;
    box-shadow: 0px 3px 6px #00000029;
    border: none;
    border-radius: 6px;
    padding: 3px;
}

.data-container-wrapper {
    height: 700px;
    border: 7px solid #FFFFFF;
    box-shadow: inset 0px 3px 20px #00000029, 0px 3px 10px #00000029;
    border-radius: 16px;
    background: #A9DABC;
    margin-top: 15px;
}

.data-container {
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

/* *******Button component*************** */

.sort-btn {
    width: 20%;
    background: #A9DABC;
    color: #000000;
    height: 32px;
    border: none;
    outline: none;
    border-radius: 137px;
    font-size: 17px;
    font-weight: 600;
    margin-right: 2.3rem;
    text-align: center;
    cursor: pointer;
}

.filters {
    font: normal normal 600 17px/30px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    margin-right: 10px;
}

.filtersSection {
    background-color: #FFF;
    height: 95px;
    padding-top: 2.2%;
    padding-left: 1.5%;
    border-radius: 13px 17px 0px 0px;
    box-shadow: 0px 3px 6px #00000029;
}

.logoutBtn {
    background-color: #000000;
    color: #FFF;
    font-size: 15px;
    padding: 6px;
    width: 100px;
    border-radius: 5px;
    margin-top: 7px;
}

.dmrRFV {
    height: 630px;
    max-height: 630px;
    overflow-y: auto;
}

.elHTkS {
    height: 630px;
    max-height: 630px;
}

.create-user-btn {
    width: 30%;
    background: #282828;
    color: #fff;
    height: 32px;
    border: none;
    outline: none;
    border-radius: 137px;
    font-size: 17px;
    font-weight: 600;
    margin-right: 2.3rem;
    text-align: center;
    padding-top: 1px;
    position: relative;
    cursor: pointer;
}

.create-user-btn::before {
    content: '';
    width: 1px;
    height: 93px;
    background-color: #00000029;
    position: absolute;
    top: -33px;
    left: -30px;
}

.search-bar {
    width: 50%;
    position: relative;
    margin-top: -10px;
}

.search-bar.preview-lesson {
    width: 100%;
}

.search-bar input {
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    background: #A9DABC;
    border-radius: 137px;
    text-align: center;
}

.search-bar span {
    position: absolute;
    top: 6px;
    right: 50px;
    width: 2px;
    height: 28px;
    background-color: #00000029;
}

.searchIcon {
    position: absolute;
    top: 10px;
    right: 20px;
}

/* **************modals***************** */

.modal-content {
    width: 550px;
    height: 550px;
    padding: 46px 40px 46px;
    box-shadow: 10px 10px 99px #00000029;
    border-radius: 13px 17px 21px 18px;
    position: relative;
    border: none;
    color: #494949;
}

.modal-header {
    padding-bottom: 2px;
}

.modal-title {
    font-weight: 700;
}

.create-user-form {
    padding: 25px 0;
    position: relative;
    font-weight: bold;
}

.form-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.form-control {
    font-size: 17px;
    width: 297px;
    height: 28px;
    background: #A9DABC;
    border-radius: 137px;
    border: none;
    outline: none;
    padding-left: 15px;
}

.form-control:focus {
    background: #A9DABC;
    border: none;
    outline: none;
}

select {
    width: 297px;
    height: 28px;
    background: #A9DABC;
    border-radius: 137px;
    border: none;
    outline: none;
    padding-left: 15px;
}

.whiteBGDropdown {
    background-color: #fff;
    text-align: center;
}

.whiteBGDropdown option {
    border-radius: 10px;
    max-height: 287px;
    overflow: auto;
    font-size: 17px;
}

option:hover {
    background-color: #A9DABC !important;
}

.btn-submit {
    width: 168px;
    height: 56px;
    background: #282828;
    color: #FFFFFF;
    font-size: 20px;
    box-shadow: 0px 3px 6px #00000029;
    border: none;
    outline: none;
    border-radius: 6px;
    margin-top: 15px;
}

.add-input-fields {
    background: #282828;
    padding: 2px 10px;
    color: #FFFFFF;
    border: none;
    outline: none;
    border-radius: 137px;
}

.create-parameter .form-label {
    margin-right: 20px;
}

/* *********Data Tabel Component******* */

.cQdWYQ {
    background-color: #A9DABC !important;
}

/* *********Preview Lessons********* */

.preview {
    background: #6A75DB;
}

.accept {
    background: #32C13C;
}

.reject {
    background: #DE5252;
}

.modal-90w {
    width: 1300px;
    max-width: 1300px;
    padding: 0;
}

.modal-90w {
    height: 780px;
}

.btn-classroom.w-200 {
    text-align: center;
    font: normal normal normal 15px/20px Montserrat;
    color: #FFFFFF;
    background: #282828;
    margin-right: 10px;
    height: 26px;
    box-shadow: 0px 3px 6px #00000029;
    border: none;
    border-radius: 6px;
    padding: 3px;
    width: 200px;
}

.user-created-success {
    background-image: url('../images/success.png');
    background-size: cover;
    background-position: center;
}

.reject-info {
    width: 100%;
    min-height: 200px;
}

/* ***********Contact form / Capture details************* */

/* .baetho-logo{
    position: absolute;
    top: 80px;
    left: 80px;
} */

.captureDetails {
    background: transparent;
    /* background-image: url('../images/backgroundWithLogo.png');
    background-size: cover;
    width: 100vw;
    height: 100%; */
}

.contact-form-wrapper-active {
    max-width: 556px;
    width: 100%;
    min-height: 27.7rem;
    background-color: #F3F3F3;
    padding: 41.6px 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 18px;
    text-align: center;
}

.contact-form-wrapper-active input {
    height: 75.2px;
    background-color: #FFFFFF;
    border: none;
    outline: none;
    border-radius: 6px;
    font-size: 19px;
    font-weight: 600;
    padding: 25px 16px;
    margin-bottom: 24px;
}

#fname,
#lname {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 3px;
    width: 100%;
}

#fname {
    padding-left: 64px;
}

#emailId {
    width: 100%;
    padding-left: 64px;
}

#phone {
    width: 100%;
    padding-left: 64px;
}

.contact-btn {
    text-align: center;
    background: #93BA55;
    padding-top: 0;
    border-radius: 9.6px;
    margin-bottom: 10px;
}

.contact-btn .btn {
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 100%;
    height: 62px;
    /* padding: 14px 120px; */
}

button:focus {
    box-shadow: none !important;
}

.user-icon {
    position: absolute;
    left: 50px;
    top: 60px;
}

.mail-icon {
    position: absolute;
    left: 50px;
    top: 165px;
}

.phone-icon {
    position: absolute;
    left: 50px;
    top: 265px;
}

.fill-details {
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, 0);
}

.fill-details h1 {
    font-size: 50px;
    font-weight: 600;
    font-style: italic;
    margin-top: 4rem;
}

.mail-error {
    color: #FF6961;
    font-weight: 600;
}

.student-mail-error {
    margin-top: 1px;
    color: #FF6961;
    font-weight: 500;
    font-size: 14px;
}

/* Lesson Styling */

.lessons-container {
    margin: 10px auto;
}

.flip-card {
    background-color: transparent;
    width: 70%;
    height: 400px;
    perspective: 1000px;
    margin: 30px 20px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #F7FDFF;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    background-color: #F7FDFF;
    color: black;
}

.flip-card-back {
    color: white;
    transform: rotateY(180deg);
}

.btn-1 a,
.btn-2 a {
    width: 90%;
    height: 10%;
    font-size: 30px !important;
    font-weight: 500;
    align-self: center;
}

.btn-1 {
    background-color: #777;
}

.btn-2 {
    background-color: #fff;
}

.btn-1 a {
    color: #fff;
    text-decoration: none;
}

.btn-2 a {
    color: #777;
    text-decoration: none;
}

.btn-1,
.btn-2 {
    width: 100%;
    height: 50%;
    margin: 5px auto;
    display: flex;
    justify-content: center;
}

/* ****************Video Demo******************* */

.view-demo-wrapper {
    margin: 10rem auto 0 auto;
}

.video-demo img {
    width: 100%;
    height: auto;
    margin-bottom: 100px;
}

.play-icon {
    width: 50%;
}

/* ************add student new***************** */

.container-fluid.add-student {
    height: 100%;
    width: 100%;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
}

.add-student-title {
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* margin-top: 40px; */
}

.add-student-title h1 {
    font: italic normal bold 43px/53px Montserrat;
    letter-spacing: 0px;
    color: #5B5B5B;

    /* font-size: 50px; */
    /* font-weight: 700; */
    /* color: #808184; */
    /* font-style: italic; */
    text-align: center;
}

.add-student-inner {
    width: 80%;
    min-height: 100px;
    background-color: #EFEFEF;
    border-radius: 10px;
    padding-top: 40px;
    margin: 0 auto;
}

/* .add-student-top {
    height: 46%;
} */

.lesson-img {
    width: 95%;
    height: auto;
    border: 7px solid #808080;
    overflow: hidden;
    box-shadow: 6px 6px 4px #0000001C;
}

.lesson-img img {
    width: 100%;
}

.add-title {
    /* font-size: 20px; */
    color: #fff;
    text-align: center;

    font: normal normal normal 20px/24px Montserrat;
    letter-spacing: 0px;
}

.add-student-btn {
    font-size: 16px;
    width: 80%;
    color: #fff;
    background-color: #93BA55;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 2px 2px 3px #0000001C;
}

.add-student-main {
    background-color: #808080;
    border-radius: 18px;
    width: 280px;
    /* height: 201px; */
    margin: 16px auto 0 auto;
    padding: 10px 20px;
}

.add-student-section {
    width: 100%;
    text-align: center;
}

.add-student-wrap {
    width: 100%;
    text-align: center;
    position: relative;
}

.add-student-form {
    text-align: center;
}

.add-student-form input {
    width: 237px;
    height: 34px;
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
    border: none;
    outline: none;
    margin-bottom: 11px;
}

.start-lesson-btn {
    font-size: 16px;
    width: 113px;
    color: #fff;
    background-color: #FF6961;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 2px 2px 3px #0000001C;
}

.start-lesson-btn:focus,
.start-lesson-btn:active {
    box-shadow: 7px 7px 5px #0000001C;
}

.student-list {
    width: 100%;
    height: 360px;
    padding: 20px;
}

.student-grid {
    display: flex;
    justify-content: center;
    align-items: center;
}

.student-single {
    position: relative;
    overflow-wrap: break-word;
    text-align: center;
}

.student-grid-mobile {
    display: none;
}


.std-id {
    position: absolute;
    bottom: 7%;
    left: 50%;
    transform: translate(-50%, 0);
    color: #FFFFFF;
    width: 80%;
    margin: 0 auto;
    font-size: 12px;
}

.student-grid img {
    width: 100%;
}

.student-grid>div:nth-child(1) {
    align-self: flex-end;
}

.student-grid>div:nth-child(3) {
    align-self: flex-end;
}

.student-grid>div:nth-child(5) {
    align-self: flex-end;
}

.student-grid>div:nth-child(7) {
    align-self: flex-end;
}

.student-grid>div:nth-child(9) {
    align-self: flex-end;
}

/* ****************Add Student******************* */

/* 
.add-student-form button{
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    border: none;
    outline: none;
    background-color: transparent;
} */

/* 
.addStudentList{
    text-align: center;
    overflow-y: auto;
    overflow-x: hidden;
    height: 400px;

} */

/* ************Classroom*************** */
.students-container {
    height: 720px;
    background-color: #BEBEBE;
    padding: 15px 25px;
    /* border-radius: 15px; */
    position: relative;
    border-radius: 20px;
}

/* .classroom-wrapper {
  
    padding: 25px;
    width: 100%;
    height: 756px;
    text-align: center;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 auto 0 auto;
    border-radius: 10px;
    height: 32%;
} */

.classroom-students {
    background-color: #FFF;
    /* width: 32%;
    margin-right: 14px;
    margin-bottom: 14px;
    height: 218px; */
    cursor: pointer;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 14px;
}

.student-img {
    width: 20%;
    margin-bottom: 5px;
}

.classroom-students p {
    margin-bottom: 0px;
    margin-top: 0;
    color: #93BA55;
    font-size: 16px;
    font-weight: 500;
}

.classroom-bg {
    width: 95%;
    margin: 0 auto;
    height: 100%;
    /* padding-top: 2rem; */
    /* background-image: url("../images/classroomBG3.png"),url("../images/classroomBG1.jpg");
    background-position: bottom, top;
    background-repeat: no-repeat;
    background-size: 100% 50%, 100% 100%; */
}

.modal-fullscreen .modal-content {
    width: 100%;
    height: 100%;
}

.classroom-title {
    text-align: center;
    /* color: #808184; */
    /* width: 80%; */
    margin: 0 auto;

    font: italic normal bold 24px/29px Montserrat;
    letter-spacing: 0px;
    color: #5B5B5B;
    text-transform: uppercase;
}

.classroom-title h1 {
    /* font-size: 50px; */
    /* font-weight: 700; */
    /* font-style: italic; */
    margin-bottom: 5px;

    font: italic normal bold 43px/53px Montserrat;
    letter-spacing: 0px;
    color: #5B5B5B;
    text-transform: uppercase;
}

.end-lesson {
    margin-top: 9px;
    text-align: center;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translate(-50%, 0);
}

.end-lesson-btn {
    font-size: 18px;
    font-weight: 500;
    /* width: 190px;
    height: 36px; */
    padding: 5px 20px;
    background-color: #FF6961;
    color: #FFFFFF;
    border: none;
    outline: none;
    border-radius: 6px;
    box-shadow: 0.79px 0.79px 1px #00000033;
}

.end-lesson-btn:focus,
.end-lesson-btn:active {
    box-shadow: 0.79px 0.79px 1px #00000033;
}

/* ************Student*************** */

.student-wrapper {
    width: 100%;
    height: 384px;
    padding: 16px;
    cursor: pointer;
    background-color: #777;
    margin-bottom: 24px;
}

.student-wrapper img {
    width: 30%;
}

.student-wrapper p {
    font-size: 2rem;
}

.progress {
    background-color: #DFDFDF;
    border-radius: 20px;
    position: relative;
    height: 20px;
    width: 120px;
}

.progress p {
    position: absolute;
    top: 2px;
    right: 10px;
    color: #000;
}

.progress-2 {
    background-color: #E0E0E0;
    border-radius: 20px;
    position: relative;
    height: 28px;
    width: 85%;
    margin: 0 auto 0;
    position: relative;
}

.progress-2 p {
    position: absolute;
    top: 5px;
    right: 10px;
    color: #000;
    font-weight: 500;
}

.individual-student-details .progress {
    margin: 10px auto 60px auto;
}

.progress-done {
    position: absolute;
    left: 0;
    top: 0;
    background: #93BA55;
    /* background: #75C494; */
    border-radius: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    transition: 1s ease 0.3s;
}

/* *************Student Details***************** */

.individual-student {
    width: 100%;
    position: absolute;
    /* top: 70px;
    left: 12px; */
    top: 50%;
    left: 12px;
    transform: translate(0, -50%);
    padding: 0rem 4rem;
    margin-top: 10px;
}

.student-details {
    min-height: 200px;
    background-color: #C1C1C1;
    /* border-radius: 32px; */
    /* padding: 6rem 0.8rem; */
    text-align: center;
}

.student-details-wrap {
    margin-top: 80px;
    position: relative;
    background-color: #eee;
    border-radius: 10px;
    padding: 32px 32px;
}

.student-screen {
    width: 100%;
    /* aspect-ratio: 16 / 9.4; */
    /* height: 740px; */
    /* padding-top: calc(100% / 1.777); */
    background: #C1C1C1 0% 0% no-repeat padding-box;
    /* border-radius: 26px; */
    padding-top: 58.75%;
    position: relative;
}

.loginMessage {
    text-align: center;
    margin-top: 50px;
}

.student-screen-iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.control {
    width: 100%;
}

.btn-control {
    font-size: 21px;
    font-weight: 500;
    /* background-color: #75c191; */
    background-color: #93BA55;
    width: 100%;
    height: auto;
    padding: 5px 10px;
    box-shadow: 2px 2px 3px #00000033;
    border-radius: 10px;
    border: none;
    border: none;
    outline: none;
    color: #fff;
}

.btn-control:focus {
    box-shadow: 2px 2px 3px #00000033;
}

.btn-green {
    background: #75BD8D 0% 0% no-repeat padding-box;
    color: #000000;
}

.btn-green:hover {
    color: #000000;
}

.btn-black {
    background: #282828 0% 0% no-repeat padding-box;
    color: #75BD8D;
}

.btn-black:hover {
    color: #75c191;
}

.btn-release {
    font-size: 21px;
    font-weight: 500;
    color: #FFFFFF;
    width: 100%;
    height: auto;
    padding: 5px 4px;
    background-color: #FF6961;
    box-shadow: 2px 2px 3px #00000033;
    border-radius: 10px;
    opacity: 1;
    border: none;
    border: none;
    outline: none;
}

.btn-control:focus {
    box-shadow: 2px 2px 3px #00000033;
}

.individual-student .back-icon {
    width: 10%;
    margin-right: 10px;
}

.individual-student .btn.back {
    font-size: 21px;
    font-weight: 500;
    color: #fff;
    background-color: #93BA55;
    /* background-color: #75c191; */
    width: 90%;
    height: auto;
    padding: 5px 10px;
    border-radius: 6px;
    margin: 0 auto;
    box-shadow: 2px 2px 3px #00000033;
}

.individual-student-img {
    width: 40%;
    margin-bottom: 10px;
}

.individual-student-details {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.individual-student-details p {
    font-size: 16px;
    /* color: #3CBD8B; */
    color: #93BA55;
}

.student-details-inner {
    position: absolute;
    top: 48px;
    left: -32px;
}

.student-box-wrap {
    width: 100%;
}

.student-box {
    background-color: #fff;
    width: 90%;
    height: auto;
    border-radius: 10px;
    margin: 0 auto 20px auto;
    padding: 10px;
    overflow-wrap: break-word;
}

.student-details-inner img {
    width: 40%;
}

.student-details-inner p {
    font-size: 32px;
    color: #eee;
}

/* *******************Admin Dashboard**************** */
.base-url-input {
    width: 100%;
    height: 30px;
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
    border-radius: 100px;
    border: none;
    outline: none;
}

.setting-label {
    font-size: 20px;
    font-weight: 500;
    font: normal normal normal 15px/20px Montserrat;
}

.uploadInput {
    outline: none;
    border: none;
    padding: 2px 10px 2px 20px;
    border-radius: 6px;
}

.choose-file {
    width: 100%;
}

/* ***********Teacher dashboard************* */
.classroom-link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
}

.copyClassUrl {
    outline: none;
    border: none;
    padding: 2px 5px;
}

/* *******Profile section start***** */
.profile-section p {
    text-align: left;
    margin-left: 50px;
    font-size: 16px;
    font-weight: 500;
}

.reset-password-form {
    text-align: left;
    margin-left: 50px;
}

.reset-password {
    background-color: #fff;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    outline: none;
    margin-bottom: 10px;
    font-family: "Montserrat", sans-serif;
    margin-right: 10px;
}

.password-submit-btn,
.password-submit-btn:hover {
    color: #ffffff;
    background-color: #000;
    margin-left: 10px;
}

.re-submit-btn,
.re-submit-btn:hover {
    text-align: center;
    font: normal normal normal 15px/20px Montserrat;
    color: #FFFFFF;
    background: #282828;
    margin-right: 10px;
    box-shadow: 0px 3px 6px #00000029;
    border: none;
    border-radius: 6px;
    padding: 3px 10px;
}

/* *******Profile section end***** */


/* **************Demo********************* */

.demo-wrap {
    background: #777;
    height: 1280px;
}

/* End Lesson Styling */

/* **********Start Classroom********** */

.back-btn {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.back-btn .btn {
    background: #282828;
    color: #FFFFFF;
}

.lesson-table {
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
}

.table-head {
    font-size: 20px;
    width: 300px;
    text-align: center;
}

.lesson-detail {
    font-size: 16px;
    width: 300px;
    text-align: center;
}

.classroom-link {
    border: 1px #282828 dashed;
    width: 80%;
    margin: 0 auto;
    height: 200px;
}

/* ****IFrame assisted learing**** */

.assisted-learning {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 75%;
}

.lessonCompleted {
    width: 100%;
    height: 100%;
}

/* **********Un assisted Player********** */

/* Player CSS */

.playerIframe {
    margin-top: 20px;
    margin-bottom: 10px;
    /* width: 100% ;
    height: 100%;
    margin: 0 auto;
    box-shadow: 0px 3px 6px #00000029; */
}

.playerOutline {
    outline: 10px solid grey;
}

.playeroverlay {
    display: none;
}

.fa-play {
    display: none;
}

.closeFullscreenIcon {
    display: none;
}

/* .unAssistedTitle{
    font-size: 50px;
    font-weight: 700;
    font-style: italic;
    text-align: center;
    color: #72B98B;
    text-transform: uppercase;
    margin-top: 20px;
   
} */

/* End Player CSS */

/* .unAssistedPlayer{
    margin: 0 auto 0 auto;
    width: 1248px;
    height: 602px;
} */

/* *****************media query****************** */

@media (max-width: 1600px) {
    .btn-control {
        font-size: 15px;
        border-radius: 5px;
    }

    .btn-release {
        font-size: 15px;
        border-radius: 5px;
    }

    .individual-student .btn.back {
        font-size: 15px;
    }
}


@media screen and (max-width:1400px) {
    /* .students-container {
        height: 700px;
        padding: 15px 25px;
    } */

    /* .end-lesson {
        bottom: 12px;
    } */
}

@media screen and (max-width:1300px) {
    /* .student-list {
        width: 100%;
        height: 290px;
        padding: 20px;
    } */

    .add-student-main {
        background-color: #808080;
        border-radius: 18px;
        width: 280px;
        /* height: 201px; */
        margin: 1rem auto 0 auto;
        padding: 10px 20px;
    }
}

@media (max-width:1200px) {
    .add-student-main {
        background-color: #808080;
        border-radius: 10px;
        width: 260px;
        margin: 10px auto 0 auto;
        padding: 20px 20px;
    }

    .add-title {
        margin-bottom: 10px;
        font-size: 16px;
    }

    .add-student-form input {
        width: 100%;
        height: 30px;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .add-student-btn {
        width: 100%;
        font-size: 14px;
    }

    .start-lesson-btn {
        width: 100%;
        font-size: 14px;
    }

    /* .std-id {
        bottom: 2%; 
        font-size: 15px;
    } */

    /* .students-container {
        height: 650px;
        padding: 15px 25px;
    } */
}

@media (min-width:1201px) and (max-width:1300px) {
    .student-list {
        width: 100%;
        height: 300px;
        padding: 20px;
    }

    .add-student-btn {
        width: 80%;
        font-size: 14px;
    }

    .start-lesson-btn {
        width: 80%;
        font-size: 14px;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .add-student-title {
        padding-top: 32px;
        padding-bottom: 16px;
    }

    .add-student-title h1 {
        font-size: 40px;
        font-weight: 700;
    }

    .add-student-main {
        width: 240px;
        /* height: 170px; */
        margin: 20px auto 0 auto;
        padding: 10px 20px;
    }

    .add-title {
        margin-bottom: 8px;
        font-size: 16px;
    }

    .add-student-form input {
        width: 100%;
        height: 30px;
        padding: 10px 20px;
        font-size: 12px;
        font-weight: 600;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .add-student-btn {
        width: 80%;
        font-size: 12px;
    }

    .start-lesson-btn {
        width: 80%;
        font-size: 12px;
    }

    .student-list {
        width: 100%;
        height: 250px;
        padding: 20px;
    }

}

/* @media (max-width:1020px) {

    .add-student-main {
        width: 220px;
        margin: 20px auto 0 auto;
        padding: 10px 20px 15px 20px;
    }
    .add-title {
        margin-bottom: 8px;
        font-size: 14px;
    }
    .add-student-form input {
        width: 100%;
        height: 28px;
        padding: 10px 20px;
        font-size: 11px;
        font-weight: 600;
        border-radius: 6px;
        margin-bottom: 10px;
    }
    .add-student-btn {
        width: 100%;
        font-size: 11px;
    }
    .start-lesson-btn {
        width: 100%;
        font-size: 11px;
        padding: 5px;
    }
    .student-list {
        width: 100%;
        height: 250px;
        padding: 20px;
    }
} */


@media (min-width:901px) and (max-width:991px) {
    .add-student-title {
        padding-bottom: 0px;
        padding-top: 35px;
    }

    .add-student-title h1 {
        font-size: 30px;
    }

    /* .add-student-inner {
        padding-top: 14px;
         min-height: 260px;
    } */

    .add-student-main {
        width: 70%;
        margin: 15px auto 0;
        min-height: 50px;
        height: 80%;
        padding: 2px 2px 10px 2px;
        text-align: center;
    }

    .add-title {
        font-size: 14px;
        margin-top: 4px;
        margin-bottom: 4px;
        margin-left: 12px;
    }

    .add-student-form input {
        width: 90%;
        height: 30px;
        margin-bottom: 6px;
        font-size: 12px;
    }

    .lesson-img {
        border: 3px solid #808080;
    }

    .student-list {
        width: 100%;
        height: 190px;
        padding: 10px;
        margin-top: 20px;
    }

    .student-single {
        align-self: flex-start;
    }

    .button-wrap {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
    }

    .button-inner {
        width: 48%;
    }

    .button-inner .add-student-btn {
        font-size: 12px;
        padding: 5px 0;
    }

    .button-inner .start-lesson-btn {
        font-size: 12px;
        padding: 5px 0
    }

    .std-id {
        bottom: 0;
        font-size: 12px;
    }

    /* .students-container {
        height: 580px;
        padding: 25px;
    } */

    /* .end-lesson-btn {
        font-size: 16px;
        width: 175px;
        height: 35px;
    } */

    .classroom-title h1 {
        margin-top: 40px;
        font-size: 40px;
    }

    .classroom-title h5 {
        font-size: 20px;
    }
}

@media (min-width:751px) and (max-width:900px) {
    .add-student-title {
        padding-bottom: 0px;
        padding-top: 18px;
    }

    .add-student-title h1 {
        font-size: 20px;
    }

    /* .add-student-inner {
        padding-top: 14px;
        min-height: 260px;
    } */

    .add-student-main {
        width: 70%;
        margin: 15px auto 0;
        min-height: 50px;
        /* height: 80%; */
        padding: 2px 2px 10px 2px;
        text-align: center;
    }

    .add-title {
        font-size: 12px;
        margin-bottom: 2px;
        margin-bottom: 2px;
        margin-left: 12px;
    }

    .add-student-form input {
        width: 90%;
        height: 20px;
        margin-bottom: 5px;
        font-size: 11px;
    }

    .lesson-img {
        border: 3px solid #808080;
    }

    .student-list {
        width: 100%;
        height: 160px;
    }

    .student-single {
        align-self: flex-start;
    }

    .button-wrap {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
    }

    .button-inner {
        width: 48%;
    }

    .button-inner .add-student-btn {
        font-size: 10px;
        padding: 5px 0;
    }

    .button-inner .start-lesson-btn {
        font-size: 10px;
        padding: 5px 0
    }

    .std-id {
        bottom: -5px;
        font-size: 11px;
    }

    .students-container {
        height: 580px;
        padding: 25px;
    }

    /* .end-lesson-btn {
        font-size: 16px;
        width: 175px;
        height: 35px;
    } */

    /* .classroom-title h1 {
        margin-top: 40px;
        font-size: 40px;
    } */

    .classroom-title h5 {
        font-size: 20px;
    }
}

@media (min-width:601px) and (max-width:750px) {
    .add-student-title {
        padding-bottom: 0px;
        padding-top: 18px;
    }

    .add-student-title h1 {
        font-size: 20px;
    }

    .add-student-inner {
        padding-top: 14px;
        min-height: 260px;
    }

    .add-student-main {
        width: 85%;
        margin: 5px auto 0;
        min-height: 50px;
        /* height: 80%; */
        padding: 2px 2px 10px 2px;
        text-align: center;
    }

    .add-title {
        font-size: 12px;
        margin-bottom: 2px;
        margin-bottom: 2px;
        margin-left: 12px;
    }

    .add-student-form input {
        width: 90%;
        height: 20px;
        margin-bottom: 5px;
        font-size: 11px;
    }

    .lesson-img {
        border: 3px solid #808080;
    }

    .student-list {
        width: 100%;
        height: 125px;
        padding: 10px;
    }

    .student-single {
        align-self: flex-start;
    }

    .button-wrap {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
    }

    .button-inner {
        width: 48%;
    }

    .button-inner .add-student-btn {
        font-size: 10px;
        padding: 5px 0;
    }

    .button-inner .start-lesson-btn {
        font-size: 10px;
        padding: 5px 0
    }

    /* .std-id {
        height: 50%;
        bottom: 0;
        display: table;
        font-size: 10px;
    } */

    /* .std-id p {
        display: table-cell;
        vertical-align: middle;
    } */

    /* .students-container {
        height: 520px;
        padding: 16px 25px;
    } */

    /* .end-lesson-btn {
        font-size: 15px;
        width: 155px;
        height: 30px;
    } */

    .classroom-students {
        padding: 10px;
    }

    /* .student-img {
        width: 35%;
    } */

    /* .classroom-title h1 {
        margin-top: 40px;
        font-size: 35px;
    } */

    .classroom-title h5 {
        font-size: 18px;
    }

}

@media (min-width:576px) and (max-width:600px) {
    .add-student-title {
        padding-bottom: 0px;
        padding-top: 18px;
    }

    .add-student-title h1 {
        font-size: 20px;
    }

    .add-student-inner {
        padding-top: 14px;
        min-height: 260px;
    }

    /* .add-student-main {
        width: 100%;
        margin: 5px auto 0;
        height: 85%;
        padding: 2px;
        text-align: center;
    } */

    .add-title {
        font-size: 10px;
        margin-bottom: 2px;
    }

    .add-student-form input {
        width: 90%;
        height: 20px;
        margin-bottom: 2px;
        padding: 10px 20px;
        font-size: 10px;
    }

    .lesson-img {
        border: 3px solid #808080;
    }

    .student-list {
        width: 100%;
        height: 115px;
        padding: 10px;
    }

    .button-wrap {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
    }

    .button-inner {
        width: 48%;
    }

    .button-inner .add-student-btn {
        font-size: 8px;
        padding: 5px 0;
    }

    .button-inner .start-lesson-btn {
        font-size: 8px;
        padding: 5px 0
    }

    /* .std-id {
        height: 50%;
        bottom: 0;
        display: table;
        font-size: 10px;
    } */

    /* .std-id p {
        display: table-cell;
        vertical-align: middle;
    } */

    /* .students-container {
        height: 570px;
        padding: 25px;
    } */

    /* .end-lesson-btn {
        font-size: 15px;
        width: 155px;
        height: 30px;
    } */

    /* .classroom-students {
        padding: 20px;
    } */

    .student-img {
        width: 40%;
    }

    /* .classroom-title h1 {
        margin-top: 40px;
        font-size: 30px;
    } */

    .classroom-title h5 {
        font-size: 18px;
    }
}

@media(max-width:575px) {
    /* .add-student-title {
        padding-top: 40px;
        padding-bottom: 8px;
    } */

    .add-student-title h1 {
        font-size: 25px;
    }

    .add-student-inner {
        padding-top: 10px;
    }

    .lesson-img {
        width: 100%;
        border: 5px solid #808080;
    }

    .add-student-inner {
        position: relative;
        margin: 0 auto;
    }

    /* .add-student-main {
        position: absolute;
        bottom: -120px;
        width: 180px;
        margin: 0 auto;
        min-height: 100px;
        padding: 10px 2px;
        text-align: center;
        left: 50%;
        transform: translate(-50%, 0);
    } */

    .add-title {
        font-size: 11px;
        margin-bottom: 2px;
    }

    .add-student-form input {
        width: 90%;
        height: 20px;
        margin-bottom: 2px;
        font-size: 10px;
    }

    .button-wrap {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
    }

    .button-inner {
        width: 48%;
    }

    .button-inner .add-student-btn {
        font-size: 10px;
        padding: 5px 0;
    }

    .button-inner .start-lesson-btn {
        font-size: 10px;
        padding: 5px 0
    }

    .student-list {
        height: 320px;
        margin: 0 auto;
    }


    .student-grid-mobile {
        display: block;
        text-align: center;
        position: relative;
    }

    .student-grid-mobile img {
        width: 65px;
    }

    /* .std-id-mobile {
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        margin: 0 auto;
        width: 50%;
        font-size: 10px;
    } */

    /* .std-id-mobile p {
        margin-bottom: 0;
    } */

    /* .students-container {
        height: 540px;
        padding: 8px 18px 20px 18px;
    } */

    /* .end-lesson-btn {
        font-size: 15px;
        width: 155px;
        height: 30px;
    } */

    /* .classroom-students {
        padding: 10px;
    } */

    /* .student-img {
        width: 45%;
    } */
    /* 
    .classroom-title {
        width: 100%;
    } */

    /* .classroom-title h1 {
        margin-top: 50px;
        font-size: 20px;
    } */

    /* .classroom-title h5 {
        font-size: 14px;
    } */

    /* .progress {
        height: 16px;
        width: 80px;
    }

    .progress p {
        margin-bottom: 0;
        top: -2px;
        font-size: 14px;
    } */

}

@media(max-width:520px) {
    /* .students-container {
        height: 520px;
        padding: 8px 18px 20px 18px;
    } */
}

@media(max-width:480px) {
    .add-student-title {
        padding-top: 30px;
        padding-bottom: 8px;
    }

    .add-student-title h1 {
        font-size: 20px;
    }

    .students-container {
        height: 480px;
        padding: 8px 18px 20px 18px;
    }

    /* .end-lesson-btn {
        font-size: 14px;
        width: 120px;
        height: 25px;
    } */

}

@media(max-width:440px) {
    /* .students-container {
        height: 460px;
        padding: 8px 18px 20px 18px;
    } */
}

@media(max-width:390px) {
    .students-container {
        height: 430px;
        padding: 8px 18px 20px 18px;
    }
}

@media(max-width:360px) {
    .add-student-title {
        padding-top: 15px;
        padding-bottom: 8px;
    }

    .add-student-title h1 {
        font-size: 16px;
    }

    .student-grid-mobile img {
        width: 55px;
    }

    .student-list {
        padding-bottom: 10px;
        height: 270px;
    }

    /* .std-id-mobile {
        font-size: 9px;
    } */

    .students-container {
        height: 380px;
        padding: 8px 18px 20px 18px;
    }

    .classroom-students {
        padding: 6px;
        border-radius: 10px;
    }

    .classroom-students p {
        font-size: 14px;
    }

    .progress {
        width: 60px;
        height: 12px;
    }

    /* .progress p {
        font-size: 12px;
    }

    .end-lesson-btn {
        font-size: 14px;
        width: 120px;
        height: 25px;
    } */

}

@media(max-width:336px) {
    .students-container {
        height: 365px;
        padding: 8px 18px 20px 18px;
    }
}

@media(max-width:300px) {
    .student-grid-mobile img {
        width: 50px;
    }

    .student-list {
        padding-left: 10px;
        padding-right: 10px;
        height: 240px;
    }

    .students-container {
        height: 340px;
        padding: 8px 18px 20px 18px;
    }

    .classroom-students p {
        font-size: 12px;
    }

    /* .end-lesson-btn {
        font-size: 12px;
        width: 100px;
        height: 25px;
    } */
}

@media(max-width:250px) {
    .student-grid-mobile img {
        width: 40px;
    }

    .student-list {
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        height: 200px;
    }

    /* .std-id-mobile {
        font-size: 9px;
    } */
}



/********************take control page *************************/
@media (max-width:991px) {
    /* .btn-control {
        font-size: 14px;
    } */

    .btn-release {
        font-size: 13px;
        padding: 5px 2px;
    }

    /* .control {
        margin-bottom: 45px;
    } */

    .progress-2 {
        height: 20px;
    }

    .progress-2 p {
        top: 0;
        font-size: 14px;
    }

    .individual-student .btn.back {
        font-size: 14px;
    }
}

@media (max-width:872px) {

    /* .individual-student{
        top: 50px;
    } */
    /* .student-details {
        border-radius: 15px;
    } */

    .btn-control {
        font-size: 11px;
    }

    .btn-release {
        font-size: 10px;
        padding: 5px 2px;
    }

    .individual-student-details p {
        font-size: 14px;
        margin-bottom: 0;
    }

    .individual-student .btn.back {
        font-size: 11px;
    }
}

@media (max-width:703px) {
    /* .student-screen {
        border-radius: 15px;
    } */

    /* .student-details {
        padding: 5px;
    } */

    /* .control {
        margin-bottom: 25px;
    } */

    .btn-control {
        font-size: 10px;
        padding: 2px 5px;
    }

    .student-box {
        padding: 5px;
    }

    .btn-release {
        font-size: 9px;
        padding: 4px 2px;
    }

    /* .progress-2 {
        height: 15px;
        margin-bottom: 28px;
    } */

    .progress-2 p {
        font-size: 12px;
    }

    .individual-student .btn.back {
        font-size: 10px;
        padding: 2px 5px;
    }
}

@media (max-width:512px) {
    /* .student-details {
        padding: 5px;
    } */

    /* .control {
        margin-bottom: 20px;
    } */

    .btn-control {
        font-size: 10px;
        width: 100%;
    }

    .student-box {
        margin-bottom: 10px;
    }

    .individual-student-details p {
        font-size: 12px;
    }

    /* .progress-2 {
        height: 13px;
        margin-bottom: 20px;
    } */

    .progress-2 p {
        font-size: 10px;
    }

    .individual-student .btn.back {
        font-size: 10px;
        padding: 2px 5px;
    }
}

.addStudentPortrait {
    display: none;
}

.studentScreenPortrait {
    display: none;
}

/* *********Tablets landscape orientation Start*********** */
@media only screen and (max-device-width : 1749px) and (orientation : landscape) {
    .students-container {
        height: 665px;
    }
}

@media only screen and (max-device-width : 1499px) and (orientation : landscape) {
    .students-container {
        height: 620px;
    }
}

@media only screen and (max-device-width : 1366px) and (orientation : landscape) {
    .addStudentPortrait {
        display: none;
    }

    /* *******Classroom students****** */
    .students-container {
        height: 510px;
        padding: 15px 22px;
        width: 811px;
    }

    .student-img {
        width: 25%;
    }

    .classroom-title h1 {
        margin-top: 0;
        font-size: 28px;
        line-height: 1.5;
    }

    .classroom-title h5 {
        font-size: 16px;
    }

    .classroom-students {
        padding: 10px;
        border-radius: 10px;
    }

    .progress {
        height: 15px;
        width: 100px;
        font-size: 14px;
        text-align: right;
    }

    .progress p {
        top: 0;
        font-size: 14px;
        text-align: right;
    }

    .end-lesson-btn {
        font-size: 16px;
        padding: 2px 20px;
        border-radius: 5px;
    }

    /* *******Add Students******** */
    .add-student-inner {
        width: 100%;
    }

    .add-student-title h1 {
        font-size: 40px;
    }

    .student-list {
        margin-top: 20px;
    }

    /* ******Individual student***** */
    .progress-2 {
        height: 20px;
    }

    .progress-2 p {
        font-size: 14px;
        top: 0;
    }

    .student-box {
        margin-bottom: 10px;
    }

    .btn-control,
    .individual-student .btn.back {
        box-shadow: 1px 1px 2px #00000033;
    }

    .btn-release {
        font-size: 14px;
        box-shadow: 1px 1px 2px #00000033;
    }
}

@media only screen and (max-device-width : 1239px) and (orientation : landscape) {
    .students-container {
        height: 470px;
        padding-top: 12px;
    }

    .classroom-students p {
        font-size: 14px;
    }

    .btn-control {
        font-size: 13px;
        padding: 5px;
    }

}

@media only screen and (max-device-width : 1024px) and (orientation : landscape) {

    /* Add Students */
    .add-student-title h1 {
        font-size: 30px;
    }

    /* students classroom */
    .students-container {
        padding: 10px 22px;
        height: 456px;
        width: 693px;
    }

    .end-lesson-btn {
        font-size: 14px;
    }

    /* *******Classroom students****** */
    /* .students-container {
        height: 443px;
        padding: 15px 25px;
    }
    .end-lesson-btn {
        font-size: 14px;
        padding: 3px 20px;
        border-radius: 5px;
    } */

    /* ******Individual student***** */
    .btn-control {
        font-size: 10px;
        padding: 5px;
        border-radius: 5px;
    }

    .progress-2 {
        height: 14px;
    }

    .progress-2 p {
        font-size: 12px;
    }

    .individual-student-details p {
        font-size: 12px;
    }

    .student-box {
        padding: 5px;
    }

    .individual-student-img {
        margin-bottom: 5px;
    }

    .btn-release {
        font-size: 10px;
    }
}

@media only screen and (max-device-width : 959px) and (orientation : landscape) {
    .students-container {
        padding: 10px 22px;
        height: 406px;
        width: 693px;
    }
}

/* *********Tablets landscape orientation End*********** */

/* *********Mobile landscape orientation Start*********** */
@media only screen and (max-device-width : 915px) and (orientation : landscape) {

    /* player iframe */
    .playerIframe {
        margin-top: 5px;
    }

    .playerIframe-bg {
        background: url('../images/player-bg.png');
        background-size: cover;
        background-position: bottom;
        background-repeat: no-repeat;
    }

    .playerOutline {
        outline: 5px solid grey;
        position: relative;
    }

    .playeroverlay {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: transparent;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        z-index: 99999;
    }

    .disablePlayerOverlay {
        display: none;
    }

    .disableCloseBtn {
        display: none;
    }

    .fa-play {
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        cursor: pointer;
        color: #fff;
        background: #93BA55;
        padding: 5px;
        border-radius: 5px;

    }

    .closeFullscreenIcon {
        display: inline-block;
        position: relative;
        top: 10px;
        left: 10px;
        padding: 5px;
        border-radius: 5px;
        background-color: #93BA55;
        cursor: pointer;
        color: #fff;
        text-align: right;
    }


    /* ******Add Student Page****** */
    .add-student-title {
        padding-top: 10px;
        padding-bottom: 5px;
        margin-top: 0;
    }

    .add-student-title h1 {
        font-size: 16px;
        line-height: 1.2;
        padding-top: 10px;
    }

    .add-student-inner {
        width: 90%;
        padding-top: 15px;
    }

    .lesson-img {
        width: 86%;
        margin-left: 20px;
    }

    .student-list {
        margin-top: 0px;
        height: 125px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .add-title {
        font-size: 10px;
        line-height: 1.5;
    }

    .add-student-main {
        width: 145px;
        margin: 5px auto 0;
        padding: 2px;
        text-align: center;
    }

    .add-student-form input {
        padding: 5px 10px;
        font-size: 10px;
        border-radius: 3px;
        margin-bottom: 2px;
    }

    .button-inner button {
        border-radius: 3px;
        margin-bottom: 5px;
    }

    .button-inner .add-student-btn {
        font-size: 9px;
        padding: 3px 0;
    }

    .button-inner .start-lesson-btn {
        font-size: 9px;
        padding: 3px 0;
    }

    .std-id {
        font-size: 10px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        width: 90%;
        top: 60%;
    }

    .std-id p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 2px;
    }

    /* ******Classroom students****** */

    .students-container {
        padding: 7px 19px;
        height: 278px;
        width: 500px;
    }

    .classroom-students {
        padding: 6px;
    }

    .student-img {
        width: 20%;
        margin-bottom: 3px;
    }

    .classroom-title h1 {
        font-size: 16px;
        line-height: 1;
    }

    .classroom-title h5 {
        font-size: 10px;
        margin-bottom: 5px;
    }

    .classroom-students p {
        font-size: 12px;
    }

    .progress {
        height: 10px;
        width: 57px;
    }

    .classroom-students .progress p {
        font-size: 9px;
    }

    .classroom-students p {
        font-size: 10px;
    }

    .end-lesson {
        bottom: 2px;
    }

    .end-lesson-btn {
        font-size: 9px;
    }

    .students-container .p-sm-1 {
        padding: 2px !important;
    }

    /* *****Individual student***** */
    .individual-student {
        padding: 0;
    }

    .btn-control {
        font-size: 9px;
        box-shadow: 0.84px 0.84px 1px #00000033;
    }

    .individual-student .btn.back {
        box-shadow: 0.84px 0.84px 1px #00000033;
    }

    /* *****Contact form***** */
    .contact-form-wrapper-active {
        max-width: 350px;
        width: 100%;
        min-height: 242px;
        padding: 20px 15px;
        transform: translate(-50%, -55%);
        border-radius: 18px;

    }

    .contact-form-wrapper-active input {
        height: 41px;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 600;
        padding: 25px 16px;
        margin-bottom: 12px;
    }

    .contact-btn {
        width: 250px;
        margin: 10px auto 0 auto;
    }

    .contact-btn .btn {
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        color: #FFFFFF;
        width: 100%;
        height: auto;
        /* padding: 14px 120px; */
    }

    .fill-details h1 {
        font-size: 16px !important;
        font-weight: 600;
        font-style: italic;
        margin-top: 36px;
    }

    .user-icon {
        left: 36px;
        top: 29px;
    }

    .mail-icon {
        left: 36px;
        top: 97px;
    }

    .phone-icon {
        left: 36px;
        top: 154px;
    }
}

@media only screen and (max-device-width : 768px) and (orientation : landscape) {

    /* ******Add student page***** */
    .student-list {
        height: 125px;
    }

    .student-grid img {
        width: 105%;
    }

    /* *****Individual student**** */
    .btn-release {
        font-size: 8px;
        /* border-radius: 3px; */
    }
}

@media only screen and (max-device-width : 700px) and (orientation : landscape) {

    /* *****Add student page**** */
    .add-student-main {
        width: 132px;
    }

    .add-title {
        font-size: 9px;
        margin-left: 10px;
    }

    .button-inner .start-lesson-btn,
    .button-inner .add-student-btn {
        font-size: 8px;
    }

    .student-list {
        padding-left: 10px;
        padding-right: 10px;
        height: 120px;
    }

    .student-grid img {
        width: 110%;
    }

    /* *****Individual student***** */
    .btn-control {
        padding: 4px;
    }

    .student-details {
        padding: 8px;
    }

    .progress-2 {
        height: 12px;
    }

    .progress-2 p {
        font-size: 10px;
    }

    .individual-student-details p {
        font-size: 11px;
    }
}

@media only screen and (max-device-width : 670px) and (orientation : landscape) {
    .students-container {
        width: 480px;
    }
}

@media only screen and (max-device-width : 639px) and (orientation : landscape) {
    .students-container {
        width: 100%;
    }
}

/* ********Mobile landscape orientation End******** */
/* **********Tablets Portrait Orientation Starts*********** */
@media only screen and (max-device-width : 1024px) and (orientation : portrait) {



    .add-student-inner {
        display: none;
    }

    .studentScreenPortrait {
        display: block;
    }

    .individual-student {
        display: none;
    }

    /* *******Classroom students****** */
    .students-container {
        height: 780px;
        padding: 16px 25px;
        width: 700px;
    }

    .classroom-title h1 {
        font-size: 28px;
    }

    .classroom-students {
        padding: 15px;
        gap: 10px;
        position: relative;
    }

    .student-img {
        width: 63%;
    }

    .end-lesson {
        bottom: 22px;
    }

    .classroom-students .studentName {
        position: absolute;
        width: 40%;
        text-align: center;
        top: 52%;
        font-size: 14px;
        color: #FFFFFF;
        font-weight: normal;
        /* white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 4px; */
    }

    /* ******Individual Student****** */
    /* .studentDetailsWrapper{
        display: flex;
    } */
    .studentScreenPortrait {
        background-color: #BDBDBD;
        height: 800px;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
    }

    .studentScreen {
        height: 50%;
        margin: 0 auto;
        position: relative;
        padding: 20px 0;
        display: flex;
        justify-content: center;
    }

    .StudentDetailsWrap {
        margin-top: 90px;
        background-color: #878787;
        height: 190px;
        padding: 13px;
        border-radius: 10px;
        display: flex;
        justify-content: center;

    }

    .studentDetailsBox {
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        position: relative;
    }

    .individual-student-img {
        width: 45%;
    }

    .studentNamePortrait {
        position: absolute;
        color: #FFFFFF;
        font-weight: 500;
        top: 50%;
        width: 35%;
        text-align: center;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    .btnControlPortrait {
        font-size: 16px;
        font-weight: 500;
        background-color: #99C056;
        padding: 5px 10px;
        color: #ffffff;
        border-radius: 5px;
        border: none;
        outline: none;
        box-shadow: 0.79px 0.79px 1px #00000033;
    }

    .btnReleasePortrait {
        font-size: 16px;
        font-weight: 500;
        background-color: #FC766A;
        padding: 5px 10px;
        color: #ffffff;
        border-radius: 5px;
        border: none;
        outline: none;
        box-shadow: 0.79px 0.79px 1px #00000033;
    }

    .studentDetailsBox .progress-2 {
        width: 50%;
    }

    .backPortrait {
        position: absolute;
        top: -50px;
        right: 15px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        background-color: #99C056;
        width: 140px;
    }

    .backPortrait:focus,
    .backPortrait:active {
        color: #ffffff;
    }

    /* **********Add Students********* */
    .addStudentPortrait {
        display: block;
    }

    .studentPortrait {
        position: relative;
    }

    .add-student-title {
        padding-top: 0;
        margin-top: 0;
    }

    .add-student-title h1 {
        font-size: 30px;
        line-height: 1.2;
    }

    .addStdBg {
        background-color: #EEEEEE;
        padding: 15px 15px 0 15px;
        border-radius: 12px;
    }

    .addStudentLessonImg {
        width: 100%;
    }

    .studentsGridPortrait {
        margin-top: 20px;
    }

    .studentPortrait img {
        width: 45%;
        margin-bottom: 15px;
    }

    .std-id-mobile {
        position: absolute;
        top: 52%;
        width: 45%;
        left: 26%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        overflow-wrap: break-word;
        word-wrap: break-word;
    }

    .std-id-mobile p {
        color: #FFFFFF;
        font-size: 12px;
        width: 80%;
        /* white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis; */
    }

    .formPortrait {
        width: 287px;
        background-color: #BDBDBD;
        margin: 14px auto 0 auto;
        padding: 5px 20px;
        box-shadow: 2px 2px 2px #00000033;
        border-radius: 13px;
    }

    .formTitle {
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 8px;
        text-align: center;
    }

    .addStudentform input {
        width: 100%;
        border-radius: 5px;
        border: none;
        outline: none;
        margin-bottom: 10px;
        padding: 2px 30px;
        color: #888887;
        font-size: 14px;
    }

    .buttonInnerPortrait button {
        width: 80%;
        border-radius: 5px;
        border: none;
        outline: none;
        padding: 2px 5px;
        margin-bottom: 5px;
        color: #FFFFFF;
        font-size: 14px;
    }

    .addStudentBtn {
        background-color: #99C056;
        box-shadow: 0.51px 0.51px 0.76px #00000033;
    }

    .startLessonBtn {
        background: #FC766A 0% 0% no-repeat padding-box;
        box-shadow: 0.51px 0.51px 0.76px #00000033;
    }

    /* .std-id-mobile{
        font-size: 10px;
        margin-bottom: 0;
    } */
}

::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-device-width : 920px) and (orientation : portrait) {

    /* Add students */
    .add-student-title {
        margin-top: 0px;
    }

    /* Classroom students */
    .students-container {
        height: 720px;
        padding: 16px 25px;
        width: 580px;
    }

    .student-img {
        width: 72%;
    }

    .classroom-students .studentName {
        width: 40%;
        top: 50%;
        font-size: 12px;
        /* white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 4px; */
    }

    .end-lesson {
        bottom: 17px;
    }

    .end-lesson-btn {
        font-size: 16px;
    }

    /* *****Individual student****** */
    .individual-student-img {
        width: 50%;
    }

    .studentNamePortrait {
        width: 38%;
        margin-left: 5px;
    }

    .btnReleasePortrait {
        font-size: 14px;
    }
}

@media only screen and (max-device-width : 766px) and (orientation : portrait) {

    /* ******Classroom students****** */
    .students-container {
        height: 650px;
        padding: 16px 25px;
        width: 500px;
    }

    .student-img {
        width: 80%;
    }

    .classroom-students {
        padding: 10px;
        gap: 4px;
    }

    .std-id-mobile p {
        font-size: 10px;
        width: 80%;
    }

    .students-container .p-sm-1 {
        padding: 4px !important;
    }

    .progress {
        height: 16px;
        width: 90px;
    }

    .progress p {
        margin-bottom: 0;
        top: -2px;
        font-size: 14px;
    }

    .end-lesson-btn {
        font-size: 14px;
    }

    /* Individual student */


}

@media only screen and (max-device-width : 660px) and (orientation : portrait) {

    /* ****Classroom students***** */
    .students-container {
        height: 546px;
        padding: 12px 20px;
        width: 400px;
    }

    .end-lesson-btn {
        font-size: 14px;
    }

    .students-container .p-sm-1 {
        padding: 3px !important;
    }

    .end-lesson {
        bottom: 13px;
    }
}

/* **********Tablets Portrait Orientation Ends*********** */

/* *********Mobile Portrait Orientation Starts********** */
@media only screen and (max-device-width : 575px) and (orientation : portrait) {

    /* ********Add student page********** */
    .add-student-title {
        padding-bottom: 0;
        padding-top: 8px;
    }

    .add-student-title h1 {
        font-size: 18px;
    }

    .studentsGridPortrait {
        margin-top: 20px;
    }

    .formTitle {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .studentPortrait img {
        width: 50px;
    }

    .classroom-students .studentName {
        width: 50%;
    }

    .formPortrait {
        width: 240px;
        padding: 5px 10px;
    }

    .buttonInnerPortrait button {
        font-size: 12px;
        border-radius: 5px;
    }

    .std-id-mobile {
        top: 48%;
    }

    .std-id-mobile p {
        font-size: 8px;
        width: 90%;
    }

    /* unassisted player */
    /* .playerOutline {
       display: none;
    } */
}

@media only screen and (max-device-width : 530px) and (orientation : portrait) {
    .playerOutline {
        display: none;
    }

    /* ****** Add students***** */
    .addStdBg {
        padding: 10px 10px 0 10px;
    }

    /* .playerIframe{
        transform: rotate(90deg);
        margin-top: 100px;
    } */
    /* *****Classroom students**** */
    .students-container {
        height: 408px;
        padding: 10px 20px;
        width: 290px;
        border-radius: 10px;
    }

    .classroom-title h1 {
        font-size: 25px;
        line-height: 1.5;
    }

    .classroom-title h5 {
        font-size: 14px;
        line-height: 1.5;
    }

    .classroom-students {
        border-radius: 8px;
    }

    .classroom-students .studentName {
        font-synthesis: 10px;
        width: 54%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .end-lesson-btn {
        font-size: 12px;
    }

    .progress {
        height: 12px;
        width: 56px;
    }

    .progress p {
        font-size: 11px;
    }

}

@media only screen and (max-device-width : 390px) and (orientation : portrait) {

    /* *****Classroom students**** */
    .students-container {
        height: 400px;
        padding: 12px 20px;
        width: 262px;
        border-radius: 10px;
    }

    .students-container {
        padding: 8px 18px;
    }

    .classroom-students {
        border-radius: 8px;
        padding: 6px;
    }

    .student-img {
        width: 90%;
    }

    .end-lesson-btn {
        font-size: 10px;
    }

    .progress {
        height: 10px;
        width: 50px;
    }

    .progress p {
        font-size: 9px;
    }

}

@media only screen and (max-device-width : 375px) and (orientation : portrait) {

    /* ********Add student page********** */
    .studentPortrait img {
        width: 56px;
    }

    .add-student-title {
        padding-top: 8px;
        margin-top: 0;
    }

    .add-student-title h1 {
        font-size: 16px;
    }

    .addStdBg {
        padding: 8px 8px 0 8px;
    }

    .formTitle {
        font-size: 14px;
    }

    .addStudentform input {
        font-size: 12px;
    }

    .studentsGridPortrait {
        margin-top: 10px;
    }

    .studentPortrait img {
        width: 45px;
    }

    .formPortrait {
        margin-top: 10px;
    }

    .std-id-mobile p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 4px;
    }
}

@media only screen and (max-device-width : 359px) and (orientation : portrait) {
    .students-container {
        width: 100%;
    }

    .formPortrait {
        width: 100%;
    }
}